import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Gallery from "../components/Gallery/Gallery";

const RoomsPage = props => {
  const {
    data: {
      allFile: { edges: images }
    },
    location
  } = props;

  const sorted = R.sortBy(R.path(["node", "name"]))(images);

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <div className="gallery-container">
            <Gallery theme={theme} images={sorted} location={location} />
          </div>
        )}
      </ThemeContext.Consumer>

      <style jsx>{`
        .gallery-container {
          margin-top: 100px;
          margin-bottom: 20px;
        }

        @below desktop {
          .gallery-container {
            margin-top: 85px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

RoomsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default RoomsPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query GalleryQuery {
    allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, dir: { regex: "/images/gallery/" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 128, height: 72) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
