import PropTypes from "prop-types";
import React from "react";
import Img from "gatsby-image";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { MdClose } from "react-icons/md";
import Title from "../Title/Title";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.primaryRef = React.createRef();
    this.secondaryRef = React.createRef();
    this.primaryModalRef = React.createRef();
    this.secondaryModalRef = React.createRef();

    const initialState = {
      currentImage: null,
      isOpenModal: false
    };

    if (props.location.hash.match(/#.+/)) {
      const imgIndex = props.images.findIndex(item => `#${item.node.id}` === props.location.hash);
      initialState.currentImage = imgIndex;
    }

    this.state = initialState;
  }

  selectImage(imgIndex) {
    this.setState({ currentImage: imgIndex, isOpenModal: true }, () => {
      this.primaryModalRef.current.sync(this.secondaryModalRef.current.splide);
    });
  }

  closeModal() {
    this.setState({ currentImage: null, isOpenModal: false });
  }

  componentDidMount() {
    this.primaryRef.current.sync(this.secondaryRef.current.splide);
    if (this.props.location.hash.match(/#.+/)) {
      const imgIndex = this.props.images.findIndex(
        item => `#${item.node.id}` === this.props.location.hash
      );
      this.selectImage(imgIndex);
    }
  }

  render() {
    const { theme, images = [] } = this.props;
    const { currentImage, isOpenModal } = this.state;

    const primaryOptions = {
      type: "loop",
      perPage: 1,
      perMove: 1,
      gap: "1rem",
      pagination: false,
      cover: true,
      heightRatio: 0.5625,
      start: currentImage || 0
    };

    const secondaryOptions = {
      type: "slide",
      rewind: true,
      gap: "0",
      pagination: false,
      fixedWidth: 134,
      fixedHeight: 78,
      cover: true,
      focus: "center",
      isNavigation: true,
      updateOnMove: true,
      arrows: false,
      start: currentImage || 0
    };

    // to prevent build error
    if (typeof window === "undefined") {
      return <p>Server Render</p>;
    }

    return (
      <React.Fragment>
        {isOpenModal && (
          <div className="gallery-modal">
            <a className="close-icon clickable" onClick={() => this.closeModal()} href="#">
              <MdClose />
            </a>
            <div className="inner">
              <div className="images">
                <div className="images-top">
                  <Splide options={primaryOptions} ref={this.primaryModalRef}>
                    {images.map(img => (
                      <SplideSlide key={img.node.id}>
                        <Img
                          fluid={img.node.childImageSharp.fluid}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt=""
                        />
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
                <div className="images-bottom">
                  <Splide options={secondaryOptions} ref={this.secondaryModalRef}>
                    {images.map(img => (
                      <SplideSlide key={img.node.id} className="item">
                        <Img
                          fixed={img.node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt=""
                        />
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="background" />
        <div className="container">
          <Title theme={theme}>Gallery</Title>
          <div className="images">
            <div className="images-top">
              <Splide options={primaryOptions} ref={this.primaryRef}>
                {images.map((img, index) => (
                  <SplideSlide key={img.node.id}>
                    <a
                      className="clickable"
                      onClick={() => this.selectImage(index)}
                      href={`#${img.node.id}`}
                    >
                      <Img
                        fluid={img.node.childImageSharp.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt=""
                      />
                    </a>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
            <div className="images-bottom">
              <Splide options={secondaryOptions} ref={this.secondaryRef}>
                {images.map(img => (
                  <SplideSlide key={img.node.id} className="item">
                    <Img
                      fixed={img.node.childImageSharp.fixed}
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt=""
                    />
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>

        <style jsx>{`
          .container {
            padding-top: 40px;
            padding-bottom: 40px;
            position: relative;
          }
          .background {
            background: ${theme.color.brand.primaryBackground};
            position: fixed;
            z-index: -1;
            top: 200px;
            height: 350px;
            max-height: 50vw;
            left: 0;
            right: 0;
          }
          .images {
            margin-top: 20px;
            padding: 3px;
            background: ${theme.color.neutral.gray.k};
            .images-top {
              margin-bottom: 3px;
            }
            .images-bottom {
            }
          }

          .clickable {
            cursor: pointer;
          }

          .gallery-modal {
            display: flex;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            background: #000000dd;
            .inner {
              width: 100%;
              padding: 0rem;
              max-height: calc(100vh - 6rem);
            }
            .close-icon {
              color: white;
              position: fixed;
              top: 1rem;
              right: 1rem;
              font-size: x-large;
            }
            .images {
              background: none;
              margin: 0;
            }
            .images-top :global(.splide),
            :global(.gatsby-image-wrapper) {
              max-height: calc(100vh - 10rem - 78px);
            }
            :global(.gatsby-image-wrapper) {
              :global(img) {
                object-fit: contain !important;
              }
            }
          }

          @below desktop {
            .container {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

Gallery.propTypes = {
  theme: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  location: PropTypes.object
};

export default Gallery;
