import PropTypes from "prop-types";
import React from "react";

const Title = ({ theme, children }) => {
  return (
    <React.Fragment>
      <div className="title">{children}</div>

      <style jsx>{`
        .title {
          font-size: 48px;
          font-family: ${theme.font.family.simpleHeader};
          text-transform: uppercase;
          color: ${theme.color.brand.primaryBackground};
        }
        @below desktop {
          .title {
            font-size: 32px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Title.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object
};

export default Title;
